import { Swiper, Navigation } from 'swiper';
Swiper.use([Navigation]);

const Gallery = (ctx) => {
    const el = ctx.el
    const swiper = new Swiper(el, {
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        slidesPerView: 'auto',
        spaceBetween: Helper.Device.isMobile() ? 14 : 0,
        loop: true,
        // centeredSlides: true,
        on: {
            'slideChange': function() {
                window.lazyLoad.update()
            }
        }
    });

    if (!Helper.Device.isSmallScreen()) {
        inView('.swiper-container')
        .on('enter', el => {
            $('.header-controls').addClass('bg-white')
        })
        .on('exit', el => {
            $('.header-controls').removeClass('bg-white')
        });
    }
}

export default Gallery