const ImageCaption = (ctx) => {
    const el = ctx.el
    const captionEl = $(el).find('figcaption')
    const btn = $(captionEl).find('button')

    $(btn).on('click', function() {
        $(captionEl).toggleClass('show')
    })
}

export default ImageCaption