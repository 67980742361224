const ResultsMasonry = (ctx) => {
    const el = ctx.el

    const masonry = new Masonry(el, {
        columnWidth: '.grid-sizer',
        itemSelector: '.grid-item',
        horizontalOrder: true,
        percentPosition: true,
        transitionDuration: '.35s',
        visibleStyle: { opacity: 1, transform: 'translateY(0px)' },
        hiddenStyle: { opacity: 0, transform: 'translateY(20px)' }
    })

    ctx.effect(() => {
        const obj = ctx.get()

        if (!obj) return

        const html = obj.html
        const append = obj.append
        const initial = obj.initial

        // if (!html && $('main').hasClass('restored')) return
        const session = JSON.parse(sessionStorage.getItem(App.storageItemIds.group))
        const hasParam = Object.keys(jsConfig.params).length > 0
        if (!session || session.path !== jsConfig.path || !html) return

        var parsed = $.parseHTML(html)
        var existing = el.querySelectorAll('.grid-item')

        var elems = [];
        var fragment = document.createDocumentFragment();
        for ( var i = 0; i < parsed.length; i++ ) {
            // console.log(parsed[i].nodeName)
            if (parsed[i].nodeName !== "DIV") continue;

            var div = document.createElement('div');
            if ($(parsed[i]).hasClass('grid-item')) {
                div.innerHTML = parsed[i].outerHTML.trim()
            } else {
                div.innerHTML = "<div class='grid-item'>" + parsed[i].outerHTML.trim() + "</div>";
            }

            var elem = div.firstChild;
            fragment.appendChild( elem );
            elems.push( elem );
        }

        if (!append) {
            $('#results').remove('.grid-item')
            masonry.remove(existing)
            masonry.layout()
        }
        $('#results').append( fragment );
        masonry.appended( elems );
    })
}

module.exports = ResultsMasonry